* {
  margin: 0;
  padding: 0
}

body {
  background: #111;  
  height: 100vh;
}

.container {
  height: 100vh;
}

.card {
  width: 350px;
  background-color: #efefef;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  top: 20px;  
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
}

@media screen and (min-width: 1200px) { 
  .card {
    position: relative;
    top: 50%;  
    left: 50%;
    transform: scale(1.4) translate(-35.8%, -35.8%);
  }
}

.image img {
  transition: all 0.5s;
}

.card .image .avatar img {
  animation-name: ani-circle;
  animation-duration: 4s; 
  animation-iteration-count: 1;
  animation-direction: alternate; 
  animation-fill-mode: forwards;
  
}

@keyframes ani-circle {
  0%   {transform: scale(1)}
  25%  {transform: scale(1)}
  50%  {transform: scale(1.32)}
  75%  {transform: scale(1.32)}
  100% {transform: scale(1.32)}
}


.congratulations {
  display: block !important;
  animation-name: ani-congratulations;
  animation-duration: 4s; 
  animation-iteration-count: 1;
  animation-direction: alternate; 
  animation-fill-mode: forwards;
  position: absolute;
  top: 0;
}

@keyframes ani-congratulations {
  0%   { opacity: 0 }
  20%  { opacity: 1 }
  50%  { opacity: 1 }
  90%  { opacity: 1 }
  100% { opacity: 0 }
}

.btn {
  height: 140px;
  width: 140px;
  border-radius: 50%
}

.name {
  font-size: 22px;
  font-weight: bold
}

.idd {
  font-size: 14px;
  font-weight: 600
}

.idd1 {
  font-size: 12px
}

.idd2 {
  margin-top: 14px;
  /* font-size: 13px; */
  text-align: center;
  white-space:nowrap;
  color: #000;
  font-weight: bold;
}

.number {
  font-size: 22px;
  font-weight: bold
}

.follow {
  font-size: 12px;
  font-weight: 500;
  color: #444444
}

.btn1 {
  border: none;
  background-color: #333;
  color: #FFF;
  font-size: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.btn1 .mint{ 
  text-align: center;
  margin: 0 20px;
}

.btn1 .tw-connect-wallet {
  background-color: #333;
  color: #FFF;
}

.btn1 .tw-connected-wallet__address{
  color: #999;
}

.btn1 .btn1-change-network {
  border-radius: 10px;
  line-height: 3em;
  padding: 0px 20px;
}

.text span {
  font-size: 13px;
  color: #545454;
  font-weight: 500
}

.icons i {
  font-size: 19px
}

/* unvisited link */
a , a:link, a:visited, a:hover, a:active {
  color: #545454;
  text-decoration: none;
}

hr .new1 {
  border: 1px solid
}

.join {
  font-size: 14px;
  color: #666;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.date {
  background-color: #ccc
}

.ant-modal-content .ant-modal-footer button{
  display: none;
}

.ant-modal-content .ant-modal-body button{
  margin: 10px 0;
}

.isLink {
  color: #000;
  /* font-weight: bold; */
  text-decoration: underline !important;
}

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');


.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}


@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}